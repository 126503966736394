
import { Credentials } from "@/interfaces";
import { defineComponent, PropType, reactive, useSlots } from "vue";

import { MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { helpers } from "@vuelidate/validators";
import * as validators from "@vuelidate/validators";

export default defineComponent({
  name: "ForgottenPassword",
  emits: ["update:modelValue", "changePage"],
  components: {
    MrUiKitButton,
    MrUiKitForm,
  },
  props: {
    modelValue: {
      type: Object as PropType<Credentials>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );
    const resetPasswordForm = reactive<Credentials>({
      email: "",
    });

    const formFields = reactive([
      {
        component: "MrUiKitInput",
        name: "email",
        props: {
          label: "Username",
          classes: ["mb-2"],
          placeholder: "Inserisci qui il tuo username",
        },
        rules: {
          required,
        },
      },
    ]);

    const onClick = async () => {
      try {
        await AuthService.resetPassword(resetPasswordForm.email);
        AuthService.logout();
        store.commit("setAlert", {
          type: "success",
          message: "Richiesta reset password inviata con successo.",
        });
        emit("changePage");
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops..Operazione non andata a buon fine.",
        });
      }
    };

    return {
      onClick,
      resetPasswordForm,
      formFields,
    };
  },
});
