
import { defineComponent, onMounted, ref } from "vue";
import LoginForm from "@/components/UI/LoginForm.vue";
import ForgottenPassword from "@/components/UI/ForgottenPassword.vue";
import FirstResetPasswordForm from "@/components/UI/FirstResetPasswordForm.vue";
import AuthService from "@/services/AuthService";
import { useRoute } from "vue-router";
import { Login } from "@/interfaces";
import { MrUiKitAlert } from "mr-ui-kit";
import { useStore } from "vuex";

export default defineComponent({
  name: "login",
  components: {
    LoginForm,
    ForgottenPassword,
    FirstResetPasswordForm,
    MrUiKitAlert,
  },
  setup() {
    const page = ref(Login.LOGIN);
    const route = useRoute();
    const token = ref<any>(null);
    const store = useStore();
    const isMounted = ref(false);

    onMounted(() => {
      token.value = route.query.token ?? null;
      if (token.value) {
        page.value = Login.FIRST_RESET_PASSWORD;
      }
      isMounted.value = true;
    });

    const goToLogin = () => {
      page.value = Login.LOGIN;
    };

    return {
      page,
      alert: store.getters.alertCfg,
      token,
      isMounted,
      goToLogin,
      window,
    };
  },
});
