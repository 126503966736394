
import { defineComponent, reactive, ref } from "vue";
import { MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import AuthService from "@/services/AuthService";
import { helpers } from "@vuelidate/validators";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoginForm",
  components: {
    MrUiKitButton,
    MrUiKitForm,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const store = useStore();

    const loginForm = ref({
      email: "",
      password: "",
    });

    const formFields = reactive([
      {
        component: "MrUiKitInput",
        name: "email",
        props: {
          label: "Username",
          placeholder: "Inserisci qui la tua email",
        },
        rules: {
          required: helpers.withMessage("Email Obbligatoria", required),
        },
      },
      {
        component: "MrUiKitInput",
        name: "password",
        props: {
          type: "password",
          label: "Password",
          placeholder: "Inserisci qui la tua password",
          classes: ["mb-2"],
        },
        rules: {
          required: helpers.withMessage("Password Obbligatoria", required),
        },
      },
    ]);

    const onClick = async () => {
      try {
        await AuthService.login(loginForm.value);
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Attenzione, credenziali errate",
        });
      }
    };

    return {
      formFields,
      loginForm,
      onClick,
    };
  },
});
