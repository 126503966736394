
import { defineComponent, reactive } from "vue";
import { MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import { helpers } from "@vuelidate/validators";
import * as validators from "@vuelidate/validators";
import { useStore } from "vuex";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "first-reset-password-form",
  emits: ["update:modelValue", "click", "changePage"],
  components: {
    MrUiKitButton,
    MrUiKitForm,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );
    const firstResetForm = reactive({
      password: "",
      confirmPassword: "",
    });

    const sameAs = helpers.withMessage(
      "Le password non coincidono",
      (value) => {
        return value === firstResetForm.password;
      }
    );

    const formFields = reactive([
      {
        component: "MrUiKitInput",
        name: "password",
        props: {
          type: "password",
          label: "Password",
        },
        rules: {
          required,
        },
      },
      {
        component: "MrUiKitInput",
        name: "confirmPassword",
        props: {
          type: "password",
          label: "Conferma password",
        },
        rules: {
          required,
          sameAs,
        },
      },
    ]);

    const onClick = async () => {
      try {
        await AuthService.firstResetPassword(
          props.token,
          firstResetForm.password
        );
        AuthService.logout();
        store.commit("setAlert", {
          type: "success",
          message: "Password generata con successo.",
        });
        emit("changePage");
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops..Operazione non andata a buon fine.",
        });
      }
    };

    return {
      formFields,
      firstResetForm,
      onClick,
    };
  },
});
